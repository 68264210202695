import React from 'react';
import './Newsletter.scss';

import styled from 'styled-components';

const Newsletter = () => {
    return (
        <>
        <h4 className="newsletter-header"><span>Newsletter</span></h4>
        <Container>
            
            <div className="gradient">
                <div className="content">
                    Don't miss out on the latest news!
                    <Button href="/newsletter">
                        Subscribe
                    </Button>
                </div>
            </div>
        </Container>
        </>
    );
};


const Button = styled.a`
    background-color: #1B7CF5;
    padding: .5em;
    border-radius: 10px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    color: white;
`;

const Container = styled.div`
    background-image: url('https://images.unsplash.com/photo-1578089815509-ed664d5b66d3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80');
    background-size: cover;
    height: 90%;
    width: 100%;
    margin-bottom: 10px;

    @media screen and (max-width: 700px){
        height: 250px;
    }

    .content {
        width: 70%;
    }
`;

export default Newsletter;