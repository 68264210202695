import React from 'react';
import './Loading.scss';

const Loading = ({loadingText}) => {
    return (
        <div className="App">
          <header className="App-header">
            <img src="https://sir.wdwnt.com/wdwnt.com/2020/02/wdwnt-logo-main-lg.png" className="App-logo" alt="logo" />
            <p>
              {loadingText}
            </p>
            
          </header>
        </div>
      );
};

export default Loading;