import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { WPPath } from '../helpers/WPPath';

import './CNNTier.scss';


const CNNTier = () => {
    const [ topStories, setTop ] = useState();
    const [ featuredStories, setFeatured ] = useState();
    const [ loading, setLoading ] = useState(false);

    async function getCNN(){
        const results = await axios(
            'https://wdwnt.com/wp-json/wp/v2/posts?_embed'
        );

        return results.data;
    }

    async function getFeatured(){
        const results = await axios(
            'https://wdwnt.com/wp-json/wp/v2/posts?tags=20474&_embed'
        );

        return results.data;
    }

    useEffect(() => {
        getCNN()
            .then((results) => {
                getFeatured()
                    .then((results1) => {
                        console.log(results);
                        setTop(results);
                        setFeatured(results1);
                        setLoading(true);
                    })
            })
    }, []);

    return (
        loading ? 
        <div id="cnn_tier">
            <div className="inner_wrapper">
                <h2 className='header_stripes'> <span>Latest Disney News</span> </h2>
                <div className="row">
                    <div className="col">
                            <ul>
                                <h3 className="article-header">Featured Stories</h3>
                                { featuredStories.map((post, index) => (
                                    index <= 4 ? 
                                    <li key={index}>
                                        <article key={index}>
                                            { index===0 ? 
                                            <a href={WPPath(post)}>
                                                <div style={{
                                                    backgroundSize: "cover",
                                                    backgroundImage: "url("+post._embedded["wp:featuredmedia"][0].source_url+")" 
                                                }} className="mainImage">
                                                </div>
                                                <div style={{
                                                    height: '80px',
                                                    overflow: 'hidden'
                                                }} dangerouslySetInnerHTML={{
                                                    __html:  post.title.rendered
                                                }} className="headline content">
                                                </div>
                                            </a>
                                            : 
                                            <a class="mini_article" href={WPPath(post)}>
                                                <img className="mini_img"
                                                     src={ post._embedded["wp:featuredmedia"][0].source_url }
                                                     alt="" />
                                                 <div dangerouslySetInnerHTML={{
                                                __html:  post.title.rendered
                                            }} className="content">
                                            </div>
                                            </a>
                                            }
                                        </article>
                                    </li>
                                    : ""
                                ))}
                            </ul>
                        </div>
                        <div className="col">
                            <ul>
                                <h3 className="article-header">Latest News</h3>
                                { topStories.map((post, index) => (
                                    index <= 4 ? 
                                    <li key={index}>
                                        <article key={index}>
                                            { index===0 ? 
                                            <a href={WPPath(post)}>
                                                <div style={{
                                                    backgroundSize: "cover",
                                                    backgroundImage: "url("+post._embedded["wp:featuredmedia"][0].source_url+")" 
                                                }} className="mainImage">
                                                </div>
                                                <div style={{
                                                    height: '80px',
                                                    overflow: 'hidden'
                                                }} dangerouslySetInnerHTML={{
                                                    __html:  post.title.rendered
                                                }} className="headline content">
                                                </div>
                                            </a>
                                            : 
                                            <a className="mini_article" href={WPPath(post)}>
                                                <img className="mini_img"
                                                     src={ post._embedded["wp:featuredmedia"][0].source_url }
                                                     alt="" />
                                                <div dangerouslySetInnerHTML={{
                                                __html:  post.title.rendered
                                            }} className="content">
                                            </div>
                                            </a>
                                            }
                                        </article>
                                    </li>
                                    : ""
                                ))}
                            </ul>
                            <button className="testbtn">
                                <a href="/feed">
                                    Load More Articles
                                </a>
                            </button>
                        </div>
                        <div className="col">
                            <div className="adtower-home">
                                
                            </div>
                        </div>
                </div>
                </div>
        </div>
        : "" 
    );
};

export default CNNTier;