import axios from 'axios';
import React, { useEffect, useState } from 'react';




const Test = () => {
    const [ data , setData ] = useState();
    
    useEffect(() => {
        const temp = axios(
            'http://wdwntauth.herokuapp.com/api'
        );


        temp.then((results) => {
            const token = results.data.token;

            sessionStorage.setItem('token_heroku', token);
            auth();
        })
    }, []);

    const auth = () => {
        var token = sessionStorage.getItem('token_heroku');

        if(token){
            setData('logged in through heroku');
        } else {
            setData('not logged in');
        }
    }

    return (
        <div>
            <h1> Test Output: </h1> {data}
        </div>
    );
};

export default Test;