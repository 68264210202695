/* eslint-disable */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './NextShows.scss';



const NextShows = () => {
    const [ shows, setShows ] = useState('');
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        var results = axios.get('https://fastpass.wdwnt.com/calendar')
        results
            .then((fastpassShows) => {
                //console.log(fastpassShows.data);
                fastpassShows.data.shows.map((show) => {
                    //console.log(show);

                    switch(show.showName) {
                        case 'Boxed In':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/BoxedIn.jpg"
                            return true;
                        case 'ParksCenter':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/Parkscenter.jpg";
                            return true;
                        case 'WDW News Tonight':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/WDWNTonight.jpg";
                            return true;
                        case 'Cosmic Reid Live':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/CosmicReidLive.jpg";
                            return true;
                        case 'Deep in the Plus':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/DeepPlus.jpg";
                            return true;
                        case 'Timekeeping':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/Timekeeping.jpg";
                            return true;
                        case 'Ink & Paint':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/InkPaint.jpg";
                            return true;
                        case 'Nerd Alert':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/NerdAlert.jpg";
                            return true;
                        case 'Ride Rehab':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/RideRehab.jpg";
                            return true;
                        case 'Ride Rehab Watches (WIGS Exclusive)':
                            show.showImage = 'https://sir.wdwnt.com/beta.wdwnt.com/RideRehab.jpg';
                            return true;
                        case 'WDWNT HersheyPark Meetup':
                            show.showImage = "https://sir.wdwnt.com/beta.wdwnt.com/WDWNTonight.jpg";
                            return true;
                        default: 
                            return true;
                    }

                    //console.log(show);
                });

                setShows(fastpassShows.data.shows);
                setLoading(true);
            })
    }, []);


    return (
        <div id="nextshows">
            <h4><span>Upcoming Shows</span></h4>
            <div className="row">
            { loading ? 
            shows.map((show, index) => (
                index <= 4 ? 
            
            <div key={index} className="gradient1 col">
                <div style={{
                    width: '100%',
                    backgroundImage: "url("+show.showImage+")",
                    backgroundSize: "cover",
                    height: "100%",
                    backgroundPosition: "top center"
                }} className="content content1">
                    <h5 style={{
                        width: '80%',
                        margin: 0,
                        padding: 0
                    }}></h5>
                    <span>{ moment(show.showTime).format('ddd M/D - h:mm az')} </span> 
                </div>
            </div>
                : ""
            ))
            : "" }
            </div>
        </div>
    );
};

export default NextShows;