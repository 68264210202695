import React, { Component } from 'react';
import styled from 'styled-components';


import {
    Topbar,
    Navbar,
    Footer 
} from '../components';


class Public extends Component {
    render(){
        return(
            <div>
                <Topbar />
                <Navbar />
                <FestivalLayout>
                    <div className="leftside">
                        <h2>Festivals</h2>
                        <div className="festivals">
                            <FestivalBox backgroundImage="https://sir.wdwnt.com/wdwnt.com/2021/03/mickey-minnie-goofy-topiary-5-8903678.jpg?w=1155&h=770"
                                        href="http://wdwnt.com/flowergarden/2021">
                                <div className="gradient">
                                    <div className="content">
                                        Flower & Garden
                                    </div>    
                                </div> 
                            </FestivalBox>
                            <FestivalBox backgroundImage="https://wdwnt.com/wp-content/uploads/2021/07/food-wine-guide-featured-3703126-1155x770.jpg"
                                        href="http://wdwnt.com/foodandwine/2021">
                                <div className="gradient">
                                    <div className="content">
                                        Food and Wine
                                    </div>    
                                </div> 
                            </FestivalBox>
                            <FestivalBox backgroundImage="https://wdwnt.com/wp-content/uploads/2021/01/festival-of-the-arts-passport-2021-14-6462175-1200x900.jpg"
                                        href="https://wdwnt.com/arts/2021/">
                                <div className="gradient">
                                    <div className="content">
                                        Festival of the Arts
                                    </div>    
                                </div> 
                            </FestivalBox>
                            <FestivalBox backgroundImage="https://wdwnt.com/wp-content/uploads/2020/11/International-festival-of-the-holidays-spaceship-earth-featured-image-hero-epcot-11272020-6177367-scaled.jpg"
                                        href="https://wdwnt.com/festivaloftheholiday/2020/">
                                <div className="gradient">
                                    <div className="content">
                                        Festival of the Holidays
                                    </div>    
                                </div> 
                            </FestivalBox>
                        </div>
                    </div>
                    <div className="rightside">
                        &nbsp;
                    </div>
                </FestivalLayout>
                <Footer /> 
            </div>
        )
    }
}


const FestivalLayout = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    max-width: 1460px;
    font-family: "Montserrat";

    @media screen and (max-width: 700px){
        flex-direction: column;
    }


    .leftside {
        flex: 8;
        
        h2 {
            width: 100%;
            display: block;
            padding-left: .5em;
            text-transform: uppercase;
            font-size: 2em;
        }

        .festivals {
            display: flex;
        }
    }

    .rightside {
        flex: 4;
    }
`

const FestivalBox = styled.a`
    flex: 4;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    color: white;
    margin: 1em;
    height: 250px;
    text-decoration: none;
`

export default Public;