/* eslint-disable */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import fire from '../fire';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory } from 'react-router';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setError ] = useState('');
    let history = useHistory();

    const handleLogin = () => {
        firebase.auth().signInWithEmailAndPassword(username, password)
            .then((userCredential) => {
                window.location.href='https://wdwnt.com/wp-login.php';
            })
            .catch((error) => {
                setError("No user was detected.");
            })
    }


    return (
        <>
        <LoginWrapper>
            <HomeButton href="/"> 
                <i class="bi bi-arrow-left"></i>
                &nbsp;&nbsp; Go back home 
            </HomeButton>
            <ImageText>
                
                <div className="gradient">
                    <div className="content">
                        <h3>Perks of having MyWDWNT:</h3>
                        <ul>
                            <li> Customized homepage </li>
                            <li> Customized homepage </li>
                            <li> Customized homepage </li>
                            <li> Customized homepage </li>
                            <li> Customized homepage </li>
                        </ul>
                    </div>
                </div>
            </ImageText>
            <LoginForm>
            <h2> Login to MyWDWNT </h2>
                <form>
                    { errorMsg != '' ? errorMsg : '' }

                    <label>Username:</label>
                    <input type="textbox" name="username" onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                    
                    <label>Password:</label>
                    <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />


                    <div class="rememberme">
                        <input type="checkbox" value="" name="remember_me" />
                        <label> Remember Me?</label>
                    </div>

                    <Button onClick={handleLogin} >
                        Login 
                    </Button>

                    <ButtonRegister href="/register">
                        Register 
                    </ButtonRegister>
                </form>
            </LoginForm>
        </LoginWrapper>
        <MobileLogin>
        <HomeButton href="/"> 
                <i class="bi bi-arrow-left"></i>
                &nbsp;&nbsp; Go back home 
            </HomeButton>
            <div className="gradient">
                <div className="loginwrapper">
                    <h2> Login to MyWDWNT </h2>
                    <form>
                        <label>Username:</label>
                        <input type="textbox" name="username" value="" placeholder="Username" />
                        
                        <label>Password:</label>
                        <input type="password" name="password" value="" placeholder="Password" />


                        <div class="rememberme">
                            <input type="checkbox" value="" name="remember_me" />
                            <label> Remember Me?</label>
                        </div>

                        <Button href="/">
                            Login 
                        </Button>

                        <ButtonRegister href="/register">
                            Register 
                        </ButtonRegister>
                    </form>
                </div>

            </div>
        </MobileLogin>
        </>
    );
};

const HomeButton = styled.a`
    margin-top: 100px;
    color: white;
    position: absolute;
    top: 0;
    left: 150px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;

    @media screen and (max-width: 700px){
        left: 27px;
        top: -32px;
    }
`

const LoginWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100vh;
    font-family: 'Montserrat';

    @media screen and (max-width: 700px){
        display: none;
    }
`

const ImageText = styled.div`
    background-image: url('https://images.unsplash.com/photo-1620235762258-101909cdbeb0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=622&q=80');
    background-size: cover;
    flex: 8;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;

        li {
            padding: .5rem 0;

            &::before {
                content: '✓';
                margin-right: 10px;
            }
        }
    }
`

const LoginForm = styled.div`
    flex: 4;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h2 {
        width: 80%;
    }

    form {
        width: 80%;
    }

    .rememberme {
        margin-top: 40px;
        display: flex;

        label {
            margin-top: 0;
            margin-left: 10px;
        }

        input[type="checkbox"] {
            width: 15px;
            height: 15px;
        }
    }

    input[type="textbox"],
    input[type="password"] {
        width: 100%;
        height: 30px;
        border: 1px solid #1B7CF5;
        padding-left: 15px;
    }

    label {
        margin: 10px 0;
        display: block;
    }
`

const MobileLogin = styled.div`
    font-family: 'Montserrat';
    background-image: url('https://images.unsplash.com/photo-1620235762258-101909cdbeb0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=622&q=80');
    background-size: cover;
    height: 100vh;
    display: none;
    position: relative;

    .loginwrapper {
        background: white;
        width: 80%;
        height: 70vh;
        padding: 1em;
        color: black;
        text-align: center;

        align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h2 {
        width: 80%;
    }

    form {
        width: 80%;
        margin-left: -20px;
    }

    .rememberme {
        margin-top: 40px;
        display: flex;

        label {
            margin-top: 0;
            margin-left: 10px;
        }

        input[type="checkbox"] {
            width: 15px;
            height: 15px;
        }
    }

    input[type="textbox"],
    input[type="password"] {
        width: 100%;
        height: 30px;
        border: 1px solid #1B7CF5;
        padding-left: 15px;
    }

    label {
        margin: 10px 0;
        display: block;
    }
    }

    @media screen and (max-width: 700px){
        display: block;
    }
`

const Button = styled.a`
    background: #1B7CF5;
    color: white;
    width: 100%;
    display: block;
    padding: 1em;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
`

const ButtonRegister = styled.a`
    background: black;
    color: white;
    width: 100%;
    display: block;
    padding: 1em;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
`

export default Login;