import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Topbar,
    Navbar
} from '../components';

let Parser = require('rss-parser');
let parser = new Parser();

const PodcastList = () => {
    const [ feed, setFeed ] = useState();
    const [ loading, setLoading ] = useState(false);

    async function getFeed(cat){
        const CORS = 'https://cors-anywhere.herokuapp.com/';
        const feed = await parser.parseURL(CORS+'https://wdwnt.com/feed/podcast/');
        const items = feed.items;

        try {
            items.forEach((item, index) => {
                if(!item.categories.includes(cat)){
                    items.splice(index, 1);
                }
            })
        } catch {

        }

        return items;
        
    }

    const handleChange = (event) => {
        var userChoice = event.target.value;
        var feed = getFeed(userChoice);
        feed.then((items) => {
            setFeed(items);
        })
    }


    useEffect(() => {
        var feed = getFeed('WDWNT');
        feed 
            .then((items) => {
                //console.log(items);
                setFeed(items);
                setLoading(true);
            })
    }, []);

    return (
        <div>
            <Topbar />
            <Navbar />
            <PodcastWrapper>
                <h2>List of Podcast Episodes</h2> 
                <form>
                    <label>Select A Show: </label>
                    <select onChange={(e) => handleChange(e)} > 
                        <option value="WDWNT"> Show All </option>
                        <option>ParksCenter </option>
                        <option>WDWNT News</option>
                    </select>
                </form>
                { loading ? 
                    feed.map((item) => (
                        <PodcastPlayer>
                            <h3>{item.title}</h3>
                        
                            <span>{item.content}</span>
        
                            <div className="player">
                            <audio controls>
                                <source src={item.enclosure.url} type="audio/mpeg"></source>
                            </audio>
                            </div>
                        </PodcastPlayer>
                )) : "" }
            </PodcastWrapper>
        </div>
    );
};


const PodcastWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    font-family: "Montserrat";

    @media screen and (max-width: 700px){
        width: 90%;
    }

    h2 {
        text-transform: uppercase;
        text-align: center;
        text-decoration: underline;
        font-size: 30px;
    }

    form {
        width: 80%;
        margin: 0 auto;

        label {
            font-weight: 700;
            font-size: 18px;
            color: #1B7CF5;
            text-transform: uppercase;
        }

        select {
            border: 1px solid #1B7CF5;
            color: #1B7CF5;
            padding: .5em;
            width: 100%;
            font-size: 18px;
        }
    }
`
const PodcastPlayer = styled.div`
    font-family: "Raleway";
    width: 100%;
    margin: 1em auto;
    padding: 2.4rem;
    box-shadow: 0 2px 4px 0 rgb(0,0,0,.2);

    h3 {
        font-family: "Montserrat";
        text-transform: uppercase;
    }

    img {
        width: 200px;
    }

    .player {
        width: 100%;
        display: block;
        margin-top: 10px;

        audio {
            width: 100%;
        }
    }

    @media screen and (max-width: 700px){
        padding: 1.4rem;
        display: block;
        width: auto;

        span {
            padding: 2em;
        }
    }
`

export default PodcastList;