import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import './Navbar.scss';

import NavbarData from '../data/Navbar';

const renderMenu = items => {
    return <ul class="mainmenu">
        { items.map(i => {
            return <li class={i.children ? 'has-dropdown' : '' }>
                { i.type === "category" ? <a href={`/category/${i.url}`}>{i.name}</a> : <a href={`/${i.url}`}>{i.name}</a> }
                { i.children && renderSub(i.children) }
            </li>
        })}
    </ul>
}

const renderSub = items => {
    return <ul class='dropdown'>
        { items.map(i => {
            return <li class={i.children ? 'has-dropdown' : '' }>
                { i.type === "category" ? <a href={`/category/${i.url}`}>{i.name}</a> : <a href={`/${i.url}`}>{i.name}</a> }
                { i.children && renderSub(i.children) }
            </li>
        })}
    </ul>
}

const Navbar = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ scrolled, setScrolled ] = useState(false);
    const [ articleName, setName ] = useState(false);
    const [ isHome, setHome ] = useState();

    const handleScroll = () => {
        const offset = window.scrollY;
        if(offset > 50){
            setScrolled(true);
            setName(true);
        } else {
            setScrolled(false);
            setName(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        const homePathPattern = /^\/$/;
        const ifHome = homePathPattern.test(window.location.pathname);
        setHome(ifHome);
    })

    return (
        <>
        <div id="navbar" class={`${scrolled ? "sticky": ""}`}>
            <div className="inner_wrapper">
                <div className="logo">
                    <a href="/">
                        <img src="https://sir.wdwnt.com/wdwnt.com/2020/02/wdwnt-logo-main-lg.png" alt="" />
                    </a>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav">
                        { renderMenu(NavbarData) }
                    </nav>
                </div>
                <div className="mobile-nav">
                    <i onClick={() => setOpen(!open)} class="bi bi-list"></i>
                    <ul class={`mobile-navnav ${open ? "active" : ""}`}>
                        <i onClick={() => setOpen(!open)} class="bi bi-x"></i>
                        <NavLink class="dropdown-test">
                            <a href="/category/parks-resorts">Parks & Resorts </a>
                        </NavLink>
                        <NavLink>
                            <a href="/category/entertainment-media">Entertainment</a>
                        </NavLink>
                        <NavLink>
                            <a href="/app">App </a>
                        </NavLink>
                        <NavLink>
                            <a href="/podcast">Podcast</a>
                        </NavLink>
                        {/* <NavLink>
                            <a href="/">Link</a>
                        </NavLink>
                        <NavLink>
                            <a href="/">Link</a>
                        </NavLink>
                        <NavLink>
                            <a href="/">Link</a>
                        </NavLink>
                        <NavLink>
                            <a href="/">Link</a>
                        </NavLink>
                        <NavLink>
                            <a href="/">Link</a>
                        </NavLink> */}
                    </ul>
                </div>

            </div>
        </div>
        { isHome ? "" :
        <ArticleName visible={props.visible} class={`${articleName ? "sticky-name": "sticky-name-hidden"}`}>
            <div dangerouslySetInnerHTML={{
                __html: props.article
            }} className="inner_wrapper">
            </div>
        </ArticleName>
        }
        </>
    );
};

const NavLink = styled.li`
    font-family: "Montserrat";
    font-size: 20px;
    padding: .5em 0;
`

const ArticleName = styled.div`
    display: none;

    .inner_wrapper {
        display: none;
        
    }
`

export default Navbar;