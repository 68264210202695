import React from 'react';
import styled from "styled-components";

const Comment = ({postID}) => {
    return (
        <CommentSection>
            <CommentForm>
                <h4>Leave a comment</h4>
                <label> Your name: </label>
                <input type="textbox" />
                <label>Your email: </label>
                <input type='textbox' />
                <textarea />
                <Button>
                    Submit
                </Button>
            </CommentForm>
            <ListComments>
                <h4>All Comments (6) </h4>
                <ul>
                    <li>comment 1</li>
                    <li>comment 1</li>
                    <li>comment 1</li>
                    <li>comment 1</li>
                    <li>comment 1</li>
                </ul>
            </ListComments>
        </CommentSection>
    );
};


const CommentSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
`;

const CommentForm = styled.form`
    textarea {
        width: 100%;
        height: 150px;
        padding: 8px;
        margin-top: 10px;
    }

    label {
        margin-left: 10px;
        padding-bottom: 10px;
    }

    input[type="textbox"] {
        width: 100%;
    
        padding: 8px;
        margin-bottom: 10px;
    }
`;
const Button = styled.a`
    background: black;
    padding: 1em;
    width: 90%;
    margin:0 auto;
    margin-top: 10px; 
    display: block;
    text-align: center;
    color: white !important;
    text-transform: uppercase;
    border-radius: 20px;
`;

const ListComments = styled.div`
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;

export default Comment;