import React from 'react';
import './Adbar.scss';

const Adbar = () => {
    return (
        <div id="adbar">
            <div className="inner_wrapper">
                <div className="adspot">
                    <a href="https://wdwnt.travel">
                        <img src="https://sir.wdwnt.com/assets/vacationeer-TopBarAD2020-v2-505x270.jpg" alt="" />
                    </a>
                </div>
                <div className="adspot">
                    <a href="https://youtube.com/wdwnt-entertainment">
                        <img src="https://sir.wdwnt.com/sponsored/Homepage%20youtube%20button.jpg" alt="" />
                    </a>
                </div>
                <div className="adspot">
                    <a href="https://wdwnt.com/2020/09/wdwnt-the-app-gets-much-need-overhaul/">
                        <img src="https://sir.wdwnt.com/sponsored/wdwnt-the-app.jpg" alt="" />
                    </a>
                </div>
                <div className="adspot">
                    <a href="https://carouselofproducts.com/index.php?index_type=promo-detail&h=6076419">
                        <img src="https://sir.wdwnt.com/sponsored/12flags_Homepage.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Adbar;