import axios from 'axios';
import React, { useEffect, useState } from 'react';


const About = () => {
    const [ content, setContent ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        const getPage = axios(
            'https://wdwnt.com/wp-json/wp/v2/pages?slug=support'
        );

        getPage.then((page) => {
            console.log(page);
            setContent(page.data[0].content.rendered);
            setLoading(true);
        });
    }, []);

    return (
        <div>
            <h2> Technical Support </h2>
            
            {loading ? 
                <div dangerouslySetInnerHTML={{
                    __html: content
                }}></div>
            : "" }
        </div>
    );
};

export default About;