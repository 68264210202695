import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './EntertainmentStyles.scss';
import { WPPath } from '../helpers/WPPath';

import Slider from 'react-slick';


const EntertainmentNews = () => {
    const [ loading, setLoading ] = useState(false);
    const [ entertainment, setEntertainment ] = useState();

    async function getPosts(){
        const results = await axios(
            'https://wdwnt.com/wp-json/wp/v2/posts?category=3255&per_page=4&offset=1&_embed'
        )

        return results.data;
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    useEffect(() => {
        getPosts()
            .then((results) => {
                console.log(results);
                setEntertainment(results);
                setLoading(true);
            });
    }, [])


    return (
        loading ? 
            <div id="entertainment">
            <h4 className="entertainment-header"><span>Entertainment</span></h4>
            <Slider {...settings} >
                {entertainment.map((post, index) => 
                    <div key={index}
                    >
                        <div style={{
                        height: '620px',
                        backgroundImage: `url('${post["_embedded"]["wp:featuredmedia"][0]["link"]}')`,
                        backgroundSize: 'cover'
                    }} className="slide">
                        <div className="gradient">
                            <div className="content">
                                <a style={{
                                    color: 'white',
                                    textDecoration: 'none'  
                                }} href={WPPath(post)}><h4 dangerouslySetInnerHTML={{
                                    __html: post.title.rendered 
                                }}></h4>
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                )}           
            </Slider>
            </div>
        : "" 
    );
};

export default EntertainmentNews;