import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import {
    Loading
} from '../pages';
import { LiveNow, Navbar, Topbar } from '../components';


const Author = () => {
    let {id} = useParams();
    const [ content, setContent ] = useState();
    const [ loading, setLoading ] = useState(false);

    

    useEffect(() => {
        async function getAuthor(id){
            const results = await axios(
                `https://wdwnt.com/wp-json/wp/v2/posts/?author=${id}&_embed`
            );
    
            return results.data;
        }

        getAuthor(id)
            .then(results=> {

                
                console.log(results);
                setContent(results);
                setLoading(true);
            })
    }, [id])



    return (
        loading ? 
            <>
                <Topbar />
                <Navbar />
                <LiveNow />
            
                    <AuthorPage>
                    <h2>{ content[0]['_embedded']['author'][0]['name'] } </h2>
                    <span style={{
                        marginBottom: '20px',
                        width: '90%'
                    }}>{ content[0]['_embedded']['author'][0]['description'] } </span>
                    <span> ### Articles </span>
                    <span>Most common tags</span>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }} className="row">
                        { content.map((post) => {
                            return (
                                <Article bgImage="https://images.unsplash.com/photo-1602032056811-0772c4f30ed9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80">
                                    <div className="gradient">
                                        <div className="content">
                                            { post.title.rendered }
                                        </div>
                                    </div>
                                </Article>
                            )
                        })}
                    </div>
                </AuthorPage> 
                
            </>
        :
            <Loading />
    );
};

const AuthorPage = styled.div`
    width: 80%;
    margin: 0 auto;
    font-family: "Montserrat";

    h2 {
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    
    span {
        margin-left: 20px;
        width: 100%;
        display: block;
    }
`

const Article = styled.div`
    border: 1px solid black;
    height: 500px;
    margin: 20px;
    width: 28%;
    background-image: url(${props => props.bgImage});
    background-size: cover;

    .content {
        width: 80%;
    }
`

export default Author;