import React, { useEffect } from 'react';
import { WPPath } from '../helpers/WPPath';

import './Featured.scss';

const Featured = ({posts}) => {
    useEffect(() => {
        console.log(posts);
    });

    return (
        <div id="featured">
            <div className='inner_wrapper'>
                <div style={{
                    backgroundImage: `url("${posts[0]['_embedded']['wp:featuredmedia'][0]['link']}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }} className="mainstory">
                    <div className="gradient">
                        
                        <a href={WPPath(posts[0])}
                            dangerouslySetInnerHTML={{
                            __html: posts[0].title.rendered
                        }} style={{
                            padding: '0 2em'
                        }} className="content">
                            
                        </a>
                    </div>
                </div>
                <div className="storystack">
                    <div style={{
                    backgroundImage: `url("${posts[1]['_embedded']['wp:featuredmedia'][0]['link']}")`,
                    backgroundSize: 'cover'
                }} className="substory">
                        <div className="gradient">
                        <a href={WPPath(posts[1])}
                            dangerouslySetInnerHTML={{
                            __html: posts[1].title.rendered
                        }} className="content">
                            
                        </a>
                    </div>
                    </div>
                    <div style={{
                    backgroundImage: `url("${posts[2]['_embedded']['wp:featuredmedia'][0]['link']}")`,
                    backgroundSize: 'cover'
                }} className="substory">
                         <div className="gradient">
                         <a href={WPPath(posts[2])}
                         dangerouslySetInnerHTML={{
                            __html: posts[2].title.rendered
                        }} className="content">
                            
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Featured;