import React from 'react';
import styled from 'styled-components';

const Adbar2 = () => {
    return (
        <AdbarWrapper>
            <div className="inner_wrapper">
            <AdPlace>
                <a href="http://patreon.com/wdwnt">
                    <img src="https://sir.wdwnt.com/beta.wdwnt.com/PatreonNew.png" alt="..." />
                </a>
            </AdPlace>
            </div>
        </AdbarWrapper>
    );
};


const AdbarWrapper = styled.div`
    margin: 0 auto;
    height: auto;
    padding: 3em 0;

    .inner_wrapper {
        width: 90%;
        margin: 0 auto;
        max-width: 1460px;
    }

    @media screen and (max-width: 700px){
        padding: 0;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }
`
const AdPlace = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 1460px;

    a {
        width: 100%;
        min-height: 300px;
        display: block;

        img {
            width: 100%;
        }
        

        @media screen and (max-width: 700px){
            min-height: 106px;
        }
    }
`

export default Adbar2;