const NavbarData = [
    {
        name: "Parks & Resorts",
        url: "parks-resorts",
        type: "category",
        children: [
            {
                name: "Walt Disney World Resort",
                url: "walt-disney-world-resort",
                type: "category",
                children: [
                    {
                        name: "Magic Kingdom",
                        url: "magic-kingdom",
                        type: "category",
                    },
                    {
                        name: "Epcot",
                        type: "category",
                        url: "epcot"
                    }, 
                    {
                        name: "Disney's Hollywood Studios",
                        type: "category",
                        url: "disneys-hollywood-studios"
                    }, {
                        name: "Disney's Animal Kingdom",
                        type: "category",
                        url: "animal-kingdom"
                    }, {
                        name: "Disney Springs",
                        type: "category",
                        url: "disney-spring"
                    }, {
                        name: "Hotels & Resorts",
                        type: "category",
                        url: "hotels-and-resorts"
                    }, {
                        name: "Festivals",
                        type: "page",
                        url: 'festival'
                    }
                ]
            }, {
                name: "Disneyland Resort",
                type: "category",
                url: "disneyland-resort",
                children: [
                    {
                        name: "Disney's California Adventure", 
                        type: "category",
                        url: "disney-california-adventure"
                    }, {
                        name: "Disneyland Park",
                        type: "category", 
                        url: "disneyland-park"
                    }
                ]
            }, {
                name: "Adventures by Disney",
                type: "category",
                url: "adventures-by-disney",
            }, {
                name: 'D23',
                type: "category",
                url: "d23"
            }, {
                name: "Tokyo Disney Resort",
                type: "category",
                url: "tokyo-disney-resort",
                children: [
                    {
                        name: "Tokyo Disneyland",
                        type: "category",
                        url: "tokyo-disneyland"
                    }, {
                        name: "Tokyo DisneySEA", 
                        type: "category",
                        url: 'tokyo-disneysea'
                    }
                ]
            }, {
                name: "Disneyland Paris",
                type: "category",
                url: "disneyland-paris"
            }, {
                name: "Hong Kong Disneyland Resort",
                type: "category",
                url: "hong-kong-disneyland-resort"
            }, {
                name: "Shanghai Disney Resort",
                type: "category",
                url: "shanghai-disney-resort"
            }, {
                name: "Universal Studios", 
                type: "category",
                url: "universal-studios",
                children: [
                    {
                        name: "Universal Studios Florida",
                        type: "category",
                        url: "universal-studi0s-florida"
                    }, {
                        name: "Universal's Island of Adventure", 
                        type: "category",
                        url: "universals-islands-of-adventure"
                    }, {
                        name: "CityWalk", 
                        type: "category",
                        url: "citywalk"
                    }, {
                        name: "Universal Orlando Hotels",
                        type: "category",
                        url: "universal-orlando-hotels"
                    }
                ]
            }
        ]
    },
    {
        name: "Entertainment",
        type: "category",
        url: "entertainment-media", 
        children: [
            {
                name: "Disney+",
                type: "category",
                url: "disneyplus"
            }, {
                name:  "Disney Interactive",
                type: "category",
                url: "disney-interactive"
            }, {
                name: "Movies", 
                type: "category",
                url: 'movies'
            }, {
                name: "Music", 
                type: "category",
                url: 'music'
            }, {
                name: "Television", 
                type: "category",
                url: 'television'
            }, {
                name: "Theatrical", 
                type: "category",
                url: 'theatrical'
            }
        ]
    }, 
    {
        name: "Refurbishment",
        type: "page",
        url: "refurbishments",
    },
    {
        name: "App", 
        type: "page",
        url: "app"
    },
    {
        name: "Videos",
        type: "page",
        url: "videos"
    },
    {
        name: "Podcasts",
        type: "page",
        url: "podcast",
        children: [
            {
                name: "The WDW News Today Podcast",
                type: "page",
                url: "podcast"
            }, {
                name: "Radio Free Podcast",
                type: "page",
                url: "podcast"
            }, {
                name: "Nerd Alert Podcast",
                type: "page",
                url: 'podcast'
            }, {
                name: 'Ride Rehab',
                type: "page",
                url: 'podcast'
            }
        ]
    }, {
        name: "Shop",
        type: "page",
        url: "shop"
    }
]

export default NavbarData;