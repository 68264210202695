import firebase from 'firebase';
import moment from 'moment';
import 'firebase/firestore';


/* Table View

"posts": {
    464145: {
        "metrics": {
            "views": 2451231
        }
    }, 
    256134: {
        "metrics": {
            "views": 1231
        }
    }
}

*/


// Accepts: daily, weekly, monthly, alltime
const GetViralPostsTime = (timeframe) => {
    switch(timeframe){
        case 'daily':
            var today = moment().format('YYYY-MM-DD');
            var viralPostsRef = firebase.database().ref(`posts/${today}`).orderByChild("count").limitToLast(3);
            viralPostsRef.once('value', snap => {
                var snapVal = [];
                snapVal.push(snap.val());
        
                snapVal.sort((a, b) => a.count > b.count ? -1 : 1)
                console.log(snapVal);
            })
            break;
        default: 
            break;
    }
}


// Function for adding tags to posts to have staff assign viral posts status
const GetStaffPicks = (tag) => {

}

// Set Count for Post view
const SetViralPost = (id) => {
    var today = moment().format('YYYY-MM-DD');
    firebase.database().ref(`posts/${today}/${id}`).once('value', snapshot => {
        if(snapshot.exists()){
            var updatedRef = firebase.database().ref(`posts/${today}/${id}`);
            updatedRef.set({
                count: firebase.database.ServerValue.increment(1)
            })
        } else {
            var createdRef = firebase.database().ref(`posts/${today}/${id}`)
            createdRef.set({
                count: 1
            })
        }

        return true;
    });    
}





export {
    SetViralPost,
    GetStaffPicks,
    GetViralPostsTime
}