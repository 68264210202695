import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Truncate from 'react-truncate-html';
import { useParams } from 'react-router';
import { WPPath } from '../helpers/WPPath';
import './Parks.scss';

import {
    Topbar, 
    Navbar,
    LiveNow
} from '../components';

const parkDisplay = [
    {
        slug: "disneyland",
        displayName: "Disneyland",
        id: 13
    }, {
        slug: "disneyworld",
        displayName: "Walt Disney World",
        id: 3177
    }, {
        slug: "tokyodisney",
        displayName: "Disney Tokyo",
        id: 3179
    }, {
        slug: "parisdisney",
        displayName: "Disney Paris",
        id: 995
    }, {
        slug: "hongkongdisney",
        displayName: "Disney Hong Kong",
        id: 459
    }, {
        slug: "universalstudios",
        displayName: "Universal Studios",
        id: 15274
    }
]

async function getPosts(id){
    const results = await axios(
        `https://wdwnt.com/wp-json/wp/v2/posts?tags=${id}&_embed`
    );
    return results.data;
}


const Parks = () => {
    let { id } = useParams();
    const [ posts, setPosts ] = useState('');
    const [ park, setPark ] = useState('');


    useEffect(() => {
        //console.log(id);
        document.body.classList.add('page');

        /* eslint-disable */
        parkDisplay.map((park) => {
                        
            if(park.slug === id){
                //console.log(park);
                
                setPark(park.displayName);
                getPosts(park.id)
                    .then((results) => {
                        setPosts(results);
                    })
                //setPosts(postPark);
            }
        })
        /* eslint-enable */
    }, [id]);

    // 1328 - blizzard beach

    return (
        <div>
            <Topbar />
            <Navbar />
            <LiveNow />
            <div id="parks">
                <h4 className="park_display"> News from: {park } </h4>
                <div class="inner_wrapper">
                       <div class="leftside">
                            { posts ? 
                            posts.map((post) => {
                                return (
                                    <a class="post-title" href={WPPath(post)}>
                                        <div class="park_posts">
                                            <img src={post['_embedded']['wp:featuredmedia'][0]['link']} alt="" />
                                                <h5><Truncate 
                                                    lines={3}
                                                    dangerouslySetInnerHTML={{
                                                        __html: post.title.rendered
                                                    }}/>
                                                </h5>
                                            {/* <span>
                                                <Truncate
                                                    lines={3}
                                                    dangerouslySetInnerHTML={{
                                                        __html: post.excerpt.rendered
                                                    }}
                                                />
                                            </span> */}
                                            {/* <a href={`/post/${post.id}`}>Read More </a> */}
                                        </div>
                                    </a>
                                )
                            })
                        : "Loading the latest news from the park" }
                        </div>
                        <div class="rightside">
                            <div className="adtower-page"></div>
                            
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default Parks;