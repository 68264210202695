/* eslint-disable */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

const FestivalMain = () => {
    let {id} = useParams();

    

    async function getPost(id){
        const result = await axios(
            `https://wdwnt.com/wp-json/wp/v2/festivals`
        );

        return result.data;
    }

    useEffect(()=> {

        switch(id){
            case 'flowerandgarden':
                id = "424024";
                return id;
        }

        getPost(id)
            .then(results => {
                console.log(results);
            });
    }, [])

    

    return (
          <h1>Festival Inner </h1>
      );
};

export default FestivalMain;