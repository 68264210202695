import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import LiveNow from '../components/LiveNow';
import ParkSearch from '../components/ParkSearch';
import Footer from '../components/Footer';
import styled from 'styled-components';
import axios from 'axios';
import { WPPath } from '../helpers/WPPath';

import Truncate from 'react-truncate';


const getArticles = async () => {
    try {
        const response = await axios.get('https://wdwnt.com/wp-json/wp/v2/posts/');

        const json = await response;
        return json;
    } catch (error) {
        console.log(error);
    }
}

const updateArticles = async (currentIndex) => {
    // console.log('current index is: '+currentIndex); 
    try {
        const response = await axios.get(`https://wdwnt.com/wp-json/wp/v2/posts/?offset=${currentIndex}`);
        const json = await response;
        return json;
    } catch(error){
        console.log(error);
    }
}

const AllPosts = () => {
    const [ articles, setArticles ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ index1, setIndex ] = useState(0);

    useEffect(() => {
        getArticles()
            .then(responseArr => {
                setArticles(responseArr.data);
                console.log('initial articles', articles);
                setLoading(true);

                setIndex(responseArr.data.length);
            })
    }, []);

    const loadmore = () => {
        console.log('test');
        updateArticles(index1)
            .then(responseArray => {
                setArticles([...articles, ...responseArray.data]);
                //console.log('new articles', articles);
                setIndex(index1 + responseArray.data.length);
                console.log(index1);
            })
    }

    return (
        <>
            <Navbar />
            <LiveNow />
            <Feed>
                <h2>All Posts </h2>
                <label>Choice of Park: </label>
                <select className="form-control" id="category_filter">
                    <option value=""> --- Select a park --- </option>
                    <option value="Universal Studios">Universal Studios </option>
                    <option value="WDW Resort"> Walt Disney World Resort </option>
                </select>
                <div className="inner_contents">
                    <div className="left_side">
                        { loading ? 
                        <div className="grid">
                            { articles.map((post, index) => 
                                <a href={WPPath(post) }>
                                    <div style={{
                                        backgroundImage: `url(${post.jetpack_featured_media_url})`
                                    }} className="bg-img"></div>
                                    <div className="item">
                                        <Truncate lines={4}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: post.title.rendered
                                            }}></div>
                                        </Truncate>
                                    </div>
                                </a>
                            )}
                            <div className="loadmore_item">
                                <button onClick={loadmore} className="btn btn-primary">
                                    Load More
                                </button>
                            </div>
                        </div>
                        : "Loading" }
                    </div>
                    <div className="right_side">
                    <AdTower
                                className="adtower">
                                    <a href="https://wdwnt.travel">
                                        <img src="https://sir.wdwnt.com/assets/vacationeer-animated-v4-300x300.gif" alt="" />
                                    </a>
                                    <a href="https://classicdisneyart.com/">
                                        <img src="https://sir.wdwnt.com/sponsored/poster-ad-final.jpg" alt="" />
                                    </a>
                            </AdTower>
                            <div className="adtower-post"></div>
                    </div>
                </div>
               
            </Feed>
            <ParkSearch />
            <Footer />
        </>
    );
};

const AdTower = styled.div`
    height: 100%;

    @media screen and (max-width: 700px){
        min-height: 600px;
        margin-bottom: 50px;
    }

    img {
        width: 100%;
        margin-bottom: 20px;
    }
`



const Feed = styled.div`
    font-family: "Montserrat";
    max-width: 1260px;
    margin: 0 auto;

    @media screen and (max-width: 800px){
        padding: 1em;
    }

    label {
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    select {
        width: 33%;
        padding: 1em;
        border-radius: 10px;
        text-transform: uppercase;

        @media screen and (max-width: 800px){
            width: 100%;
        }
    }

    .inner_contents {
        margin-top: 1em;
        display: flex;

        @media screen and (max-width: 800px){
            flex-direction: column;
        }

        .left_side {
            width: 70%;
            float: left;
            height: auto;

            @media screen and (max-width: 800px){
                width: 100%;
            }

            .grid {
                a {
                    width: 27%;
                    float: left;
                    height: 300px;
                    margin: 1.5em;
                    text-decoration: none;
                    color: black;
                    font-weight: 700;

                    @media screen and (max-width: 800px){
                        width: 100%;
                        margin: .5em;
                    }

                    


                    .bg-img {
                        width: 100%;
                        height: 200px;
                        background-size: cover;
                        background-position: 50% 50%;
                        float: left;

                        @media screen and (max-width: 800px){
                            margin-bottom: 1em;
                        }
                    }

                    .item {
                        margin-top: 15px;
                        
                    }
                }
            }
        }

        .loadmore_item {
            width: 100%;
            margin-top: 1em;
            margin-bottom: 2em;
            float: left;

            button {
                width: 33%;
                margin: 0 auto;
                display: block;
                padding: 1em;
                border: 1px solid #2B6EF2;
                background: #2B6EF2;
                color: white;
                border-radius: 10px;

                @media screen and (max-width: 800px){
                    width: 100%;
                }
            }
        }

        .right_side {
            width: 30%;
            float: left;
            height: auto;

            @media screen and (max-width: 800px){
                width: 100%;
                padding-bottom: 2em;
            }
        }
    }
`

export default AllPosts;