import React from 'react';
import './MidNews.scss';


import {
    NextShows,
    EntertainmentNews,
    Newsletter
} from '../components';

const MidNews = ({posts}) => {
    return (
        <div id="midsection">
            <div className="inner_wrapper">
                <div className="leftcol">
                    <div className="nextshows">
                        <NextShows />
                    </div>
                    <div class="row">
                        <div className="entertainment_section">
                            <EntertainmentNews />
                        </div>
                        <div className="adspot">
                            <Newsletter />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MidNews;