import React from 'react';
import styled from 'styled-components';

const Footer = () => {
    return (
        <FooterWrapper>
            <InnerWrapper>
                <ul>
                    <li><a href="/about">About</a></li>
                    <li><a href="/staff">Staff</a></li>
                    <li><a href="/advertise">Advertise</a></li>
                    <li><a href="/newsletter">Newsletter</a></li>
                    <li><a href="/apply">Work With Us!</a></li>
                    <li><a href="/disclosure">Disclosure/Disclaimer</a></li>
                    <li><a href="/support">Technical Support </a></li>
                    <li><a href="mailto:wdwnewstoday@gmail.com">Contact</a></li>
                </ul>
                <span className="copyright">
                    Copyright 2021 WDWNT, LLC. All Rights Reserved. | Privacy Policy | WDWNT.com is an unofficial fan site and not 
                    affliated with the Walt Disney Company.
                </span>
            </InnerWrapper>
        </FooterWrapper>
    );
};

const FooterWrapper = styled.div`
    background: black;
    color: white;
    font-family: "Montserrat";
    padding: 4em 0;
`

const InnerWrapper = styled.div`
    width: 75%;
    flex-direction: column;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
        color: grey;
        margin-top: 20px;
        font-size: .8em;
    }

    ul {

        list-style: none;
        padding: 0px;

        li {
            float: left;

            a {
                color: white;
                text-decoration: none;
                padding: 1em;
                text-transform: uppercase;
                font-weight: 700;
            }

            @media screen and (max-width: 700px){
                width: 100%;
                padding: .5em 0;
            }
        }
    }
`



export default Footer;