import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

class WDW3Col extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            wdw_articles: [],
            dl_articles: [],
            wdw_loading: false,
            dl_loading: false 
        }
    }

    componentDidMount(){
        axios.get('https://wdwnt.com/wp-json/wp/v2/posts/?per_page=6')
            .then((wdw_results) => {
                axios.get('https://wdwnt.com/wp-json/wp/v2/posts/?per_page=6')
                    .then((dl_results) => {
                        console.log(wdw_results.data);
                        this.setState({
                            wdw_articles: wdw_results.data,
                            wdw_loading: true,
                            dl_articles: dl_results.data,
                            dl_loading: true 
                        })
                    })
            });
    }

    render(){
        return(
            <WDW3Wrapper>
                <div className="inner_wrapper">
                    <h2 className="header_stripes">
                        <span>Walt Disney News</span>
                    </h2>
                    <div className="row">
                        { this.state.wdw_loading ? 
                            this.state.wdw_articles.map((article, index) => 
                                <div key={index} style={{
                                        backgroundImage: `url(${article.jetpack_featured_media_url})`,
                                        backgroundSize: 'cover',
                                        height: '250px'
                                     }} 
                                     className="article">
                                         <div className="contents gradient">
                                            <h5 dangerouslySetInnerHTML={{
                                                __html: article.title.rendered
                                            }}></h5>
                                        </div>
                                </div>
                            )
                        : "" }
                    </div>
                    <h2 className="header_stripes">
                        <span>Disneyland News</span>
                    </h2>
                    <div className="row">
                        { this.state.dl_loading ? 
                            this.state.dl_articles.map((article, index) => 
                            <div key={index} style={{
                                backgroundImage: `url(${article.jetpack_featured_media_url})`,
                                backgroundSize: 'cover',
                                height: '250px'
                             }} 
                             className="article">
                                 <div className="contents gradient">
                                    <h5 dangerouslySetInnerHTML={{
                                        __html: article.title.rendered
                                    }}></h5>
                                </div>
                        </div>
                            )
                        : "" }
                    </div>
                </div>
            </WDW3Wrapper>
        )
    }
}


const WDW3Wrapper = styled.div`
    font-family: "Montserrat";

    .inner_wrapper {
        width: 87%;
        max-width: 1460px;
        margin: 0 auto;

        .article {
            .contents {
                height: 250px;
                text-align: center;

                h5 {
                    width: 80%;
                }
            }
        }

        h2.header_stripes {
            position: relative;
            text-transform: uppercase;
            color: #0A1030;

            &::after {
                content: "";
                border-top: 4px solid #D9D9D9;
                border-bottom: 4px solid #D9D9D9;
                height: 1px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 11px;
                z-index: -1;
            }
        }

        span {
            padding-right: 10px;
            background: white;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
        }

        .article {
            flex-basis: 32%;
            background: red;
            height: 250px;
            margin-right: 10px;
            margin-bottom: 10px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            @media screen and (max-width: 700px){
                flex-basis: 46%;
            }
        }
    }
`

export default WDW3Col;