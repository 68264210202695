import moment from 'moment';

const WPPath = (post) => {
    // convert WP API post to a YYYY/MM/slug url

    const WPDate = post.date;

    var year = moment(WPDate).format('YYYY');
    var month = moment(WPDate).format('MM');

    return "/"+year+"/"+month+"/"+post.slug;
};


export {
    WPPath
}