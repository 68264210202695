import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const About = () => {
    const [ content, setContent ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        const getPage = axios(
            'https://wdwnt.com/wp-json/wp/v2/pages?slug=app'
        );

        getPage.then((page) => {
            console.log(page);
            setContent(page.data[0].content.rendered);
            setLoading(true);
        });
    }, []);

    return (
        <div>
            <h2> About Us </h2>
            
            {loading ? 
                <AboutPage>
                    <div dangerouslySetInnerHTML={{
                        __html: content
                    }}></div>
                </AboutPage>
            : "" }
        </div>
    );
};

const AboutPage = styled.div`
    p {
        width: 100%;
        display: block;
    }
    
    a {
        float: left;
        margin-right: 2em;
    }
`

export default About;