import firebase from 'firebase/app';

var firebaseConfig = {
    apiKey: "AIzaSyAP6Odg3cQI-peTQDTrgIkYG3WjtOcUmS0",
    authDomain: "wdwnt-headless.firebaseapp.com",
    projectId: "wdwnt-headless",
    storageBucket: "wdwnt-headless.appspot.com",
    messagingSenderId: "976928633759",
    appId: "1:976928633759:web:aeb61001a7825e28c6c3aa"
  };
  // Initialize Firebase
var fire = firebase.initializeApp(firebaseConfig);

export {
  fire
}