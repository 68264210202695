import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { 
    LiveNow, 
    Navbar, 
    Topbar
} from '../components';

const Tag = () => {
    let { id } = useParams();

    async function getPost(id){
        const results = await axios(
            `https://wdwnt.com/wp-json/wp/v2/posts/?tag=${id}&_embed`
        );

        return results.data;
    }

    useEffect(() => {
        getPost(id)
            .then((results) => {
                console.log(results);
            });
    }, [id])


    return (
        <>
            <Topbar />
            <Navbar />
            <LiveNow />
        
        </>
    );
};

export default Tag;