import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import styled from 'styled-components';

import {
    SetViralPost
} from '../helpers/Viral';

import './Post.scss';

import {
    Loading
} from '../pages';
import { Footer, LiveNow, Navbar, Topbar } from '../components';


const Post = () => {
    let {slug} = useParams();
    const [ content, setContent ] = useState();
    const [ loading, setLoading ] = useState(false);


    async function getPost(slug){
        const result = await axios(
            `https://wdwnt.com/wp-json/wp/v2/posts?slug=${slug}&_embed`
        );

        return result.data;
    }


    useEffect(() => {
        document.body.classList.add('single');

        if(window.twttr){
            window.twttr.widgets.load();
        }

        getPost(slug)
            .then(results => {
                console.log(results[0]);
                setContent(results[0]);
                SetViralPost(results[0].id);
                setLoading(true);
            })
    }, [slug]);


    return (
        loading ? 
            <>
                <Topbar />
                <Navbar visible="true" article={content.title.rendered} />
                <LiveNow />
                <div id="post_content">
                    <div className="inner_wrapper">
                        <div className="left_side">
                            { loading ?
                            <> 
                            <FeaturedImage
                                bgImage={content['_embedded']['wp:featuredmedia'][0]['source_url']}
                            >
                            </FeaturedImage>
                            <h1 dangerouslySetInnerHTML={{
                                __html: content.title.rendered
                            }}></h1>
                            <h5> Posted By: &nbsp;
                                <a style={{
                                    color: '#1B7CF5',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }} href={`/author/${content.author}`}>
                                    { content['_embedded']['author'][0]['name'] } 
                                </a>
                            </h5>
                            <h5> Posted: { moment(content.date).fromNow() } </h5>
                            <div className="content">
                                <span dangerouslySetInnerHTML={{
                                    __html: content.content.rendered 
                                }}></span>
                            </div>
                            <div className="author_info">
                                <h3>{ content['_embedded']['author'][0]['name'] }</h3>
                                <p> { content['_embedded']['author'][0]['description'] } </p>
                                <a href={`/author/${content.author}`}>
                                    See All Posts 
                                </a>
                            </div>
                            <PreviousPost
                                bgImage={content['jetpack-related-posts'][0]['img']['src']}
                            >
                                <div className="gradient">
                                    <div style={{
                                        width: "70%",
                                        height: '100px',
                                        overflow: 'hidden'
                                    }} className="content">
                                        { content['jetpack-related-posts'][0]['title'] }
                                    </div>
                                </div> 
                            </PreviousPost>
                        
                            <NextPost
                                 bgImage={content['jetpack-related-posts'][1]['img']['src']}
                            >
                                <div className="gradient">
                                    <div style={{
                                            width: "70%",
                                            height: '100px',
                                            overflow: 'hidden'
                                        }} className="content">
                                            { content['jetpack-related-posts'][1]['title'] }
                                    </div>
                                </div> 
                            </NextPost>
                            </> : '' }
                            
                            
                            {/* <Comment postID={content.id} /> */}
                        </div>
                        <div className="right_side">
                            <AdTower
                                className="adtower">
                                    <a href="https://wdwnt.travel">
                                        <img src="https://sir.wdwnt.com/assets/vacationeer-animated-v4-300x300.gif" alt="" />
                                    </a>
                                    <a href="https://classicdisneyart.com/">
                                        <img src="https://sir.wdwnt.com/sponsored/poster-ad-final.jpg" alt="" />
                                    </a>
                            </AdTower>
                            <div className="adtower-post"></div>
                        </div>
                            
                    </div>
                </div>
                <Footer />
            </>
        :
            <Loading />
    );
};

const AdTower = styled.div`
    height: 100%;

    @media screen and (max-width: 700px){
        min-height: 600px;
        margin-bottom: 50px;
    }

    img {
        width: 100%;
        margin-bottom: 20px;
    }
`

const FeaturedImage = styled.div`
    height: 400px;
    width: 100%;
    background-image: url(${props => props.bgImage});
    background-size: cover;
    background-position: center;
`

const PreviousPost = styled.div`
    width: 47.7%;
    margin: 20px 10px 20px 0;
    height: 200px;
    background-image: url(${props => props.bgImage});
    background-size: cover;
    float: left;

    @media screen and (max-width: 700px){
        width: 100%;
        margin: 5px 0;
        margin-top: 20px;
    }
`
const NextPost = styled.div`
    width: 47.7%;
    margin: 20px 0px 20px 10px;
    float: left;
    height: 200px;
    background-image: url(${props => props.bgImage});
    background-size: cover;

    @media screen and (max-width: 700px){
        width: 100%;
        margin: 5px 0;
    }
`

export default Post;