import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route 
} from 'react-router-dom';

import {
  Home, 
  Loading,
  Test,
  Parks, 
  Post,
  Author, 
  Login,
  Category,
  Tag,
  Register,
  PodcastList,
  About,
  Advertise,
  Staff, 
  NewsletterPage,
  WorkWithUs,
  Disclaimer,
  Support,
  AppDownload,
  FestivalInner
} from './pages';

import axios from 'axios';
import React from 'react';
import Public from './pages/static/Public';
import FestivalMain from './pages/FestivalMain';
import AllPosts from './pages/AllPosts';


class App extends React.Component {
  state = {
    data: [],
    loading: false 
  }

  loadData = async event => {


    const res = await axios.get('https://wdwnt.com/wp-json/wp/v2/posts/?_embed',{
      responseType: 'json',
      headers: {
        'Accept': "application/json",
        'Content-Type': 'application/json'
      }
    });

    return res.data;
    
  }

  componentDidMount(){
    this.loadData()
      .then(res => {

        var tempArray = res.slice(0,3);
        var topNews = res.slice(4,9);
        var sidebarNews = res.slice(1, 7);

        this.setState({
          data: res,
          loading: true,
          featured: tempArray,
          topnews: topNews,
          sidebarNews: sidebarNews
        })
      })
  }

  render(){
    return (
      <Router>
        { this.state.loading ?   
        <Switch>
            <Route exact path="/">
              <Home posts={this.state} />
            </Route>
            <Route path="/parks/:id">
              <Parks />
            </Route>
            <Route path="/rides">
              Hopefully a ride/wait time page for each page
            </Route>
            <Route path="/test">
              <Test />
            </Route>
            <Route path="/:year/:month/:slug">
              <Post />
            </Route>
            <Route path="/post/:slug">
              <Post />
            </Route>
            <Route path="/author/:id">
              <Author />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/tags/:id">
              <Tag />
            </Route>
            <Route path="/category/:id">
              <Category />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/feed">
              <AllPosts />
            </Route>
            <Route path="/podcast">
              <PodcastList />
            </Route>
            <Route exact path="/festival">
              <FestivalMain />
            </Route>
            <Route path="/festival/:id">
              <FestivalInner />
            </Route>
            <Public>
              <Route exact path="/app" component={AppDownload} />
              <Route exact path="/about" component={About} />
              <Route exact path="/advertise" component={Advertise} />
              <Route exact path="/staff" component={Staff} />
              <Route exact path="/newsletter" component={NewsletterPage} />
              <Route exact path="/apply" component={WorkWithUs} />
              <Route exact path="/disclosure" component={Disclaimer} />
              <Route exact path="/support" component={Support} />
            </Public>
          </Switch>
        : <Loading loadingText="Gathering new stories..." /> 
        }
      </Router>
    );
  }

}


export default App;
