import React, { Component } from 'react';
import styled from 'styled-components';


import {
    Topbar,
    Navbar,
    Footer 
} from '../../components';


class Public extends Component {
    render(){
        return(
            <div>
                <Topbar />
                <Navbar />
                <PublicLayout>
                    <div className="leftside">
                        { this.props.children }
                    </div>
                    <div className="rightside">
                        &nbsp;
                    </div>
                </PublicLayout>
                <Footer /> 
            </div>
        )
    }
}


const PublicLayout = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    max-width: 1460px;
    display: flex;
    font-family: "Montserrat";
    flex-direction: row;

    @media screen and (max-width: 700px){
        flex-direction: column;
    }


    .leftside {
        flex: 8;
    
    }

    .rightside {
        flex: 4;
    }
`

export default Public;