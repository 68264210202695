import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { WPPath } from '../helpers/WPPath';
import Truncate from 'react-truncate-html';

import './Category.scss';

import {
    Loading
} from '../pages';
import { Footer, LiveNow, Navbar, Topbar } from '../components';


const Category = () => {
    let {id} = useParams();
    const [ content, setContent ] = useState();
    const [ catName, setName ] = useState();
    const [ loading, setLoading ] = useState(false);


    async function getPost(id){
        const catID = axios(`https://wdwnt.com/wp-json/wp/v2/categories?slug=${id}`)

        console.log('inside getPost', catID);

        catID 
            .then(results => {
                console.log('catID', results.data[0].id);
                console.log('catID name', results.data[0].name);
                setName(results.data[0].name);
                const catSearch = axios(
                    `https://wdwnt.com/wp-json/wp/v2/posts/?categories=${results.data[0].id}&_embed`
                )

                catSearch
                    .then((results1) => {
                        console.log('catsearch', results1.data);
                        setContent(results1.data);
                        setLoading(true);
                    })
            })
    }

    const fetchMoreData = () => {
        console.log('load more 12');
    }

    useEffect(() => {
        document.body.classList.add('page');

        getPost(id)
            .then(results => {
                
            })
    }, [id]);


    return (
        loading ? 
            <>
                <Topbar />
                <Navbar />
                <LiveNow />
                <div id="post_content">
                    { loading ? 
                    <div className="inner_wrapper">
                        
                        <div className="left_side">
                            <h2 dangerouslySetInnerHTML={{
                                __html: catName
                            }}></h2>
                           <div style={{
                               display: 'flex',
                               flexWrap: 'wrap'
                           }}>
                               { content.map((post) => 
                                    <PostItem bgimage={post.jetpack_featured_media_url} className="card">
                                        <div className="card-image-top">
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <Truncate lines={5} dangerouslySetInnerHTML={{
                                                    __html: post.title.rendered
                                                }}></Truncate>
                                            </h5>
                                        </div>
                                    </PostItem>
                                )}   
                                <div className="loadmore_btn">
                                    <button onclick={ fetchMoreData() } lclassName="btn btn-primary">
                                        Load More 
                                    </button>
                                </div>
                           </div>
                        </div>
                        <div className="right_side">
                            <div className="adtower-sidebar">
                                
                            </div>
                        </div>
                            
                    </div>
                    : "" }
                </div>
                <Footer />
            </>
        :
            <Loading />
    );
};

const PostItem = styled.div`
    width: 30%;
    padding: 1em 10px 2em 10px;

    @media screen and (max-width: 700px){
        width: 43%;
    }

    .card-body {
        margin-top: 10px;
    }

    .card-image-top {
        background-image: url(${props => props.bgimage});
        background-size: cover;
        background-position: 50% 50%;
        height: 150px;
    }

    h5 {
        margin: 0;
        padding: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }
`

export default Category;