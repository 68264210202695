import React, { useEffect }  from 'react';
import './Home.scss';

import {
    Topbar,
    Navbar,
    Featured,
    Adbar,
    Adbar2, 
    MidNews,
    ParkSearch,
    LiveNow,
    CNNTier,
    Footer 
} from '../components';

import {
    GetViralPostsTime
} from '../helpers/Viral';
import WDW3Col from '../components/WDW3Col';

const Home = ({posts}) => {   
    useEffect(() => {
        document.body.classList.add('home');
        //SetViralPost(464555);
        GetViralPostsTime('daily');
    }, []);
    
    return (
        <div style={{
            padding: '0',
            margin: '0',
            width: '100%'
        }}>
            <Navbar />
            <LiveNow />
            <Featured posts={posts.featured} />
            <Adbar />
            <CNNTier />
            <WDW3Col />
            <MidNews posts={posts.sidebarNews } />
            <Adbar2 />
            <ParkSearch />
            <Footer />
        </div>
    );
};

export default Home;