import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './LiveNow.scss';

const LiveNow = () => {
    const [ isLive, setIsLive ] = useState(false);
    const [ liveshow, setLive ] = useState('');
    const [ latestVideo, setLatest ] = useState(false);
    const [ mostRecentVideo, setMostRecent ] = useState();

    useEffect(() => {
        async function fetchData(){
            const response = await axios(
                'http://fastpass.wdwnt.com/wdwnt/broadcasts'
            );

            const mostRecent = "https://www.youtube.com/playlist?list=UURIVd5Ci1bTQqJB_T4q_Jgg&playnext=1&index=1";



            //console.log(response.data);


            // if live show live
            // else festival dates (get dates from Jason)
            // else latest news today video


            if(response.data.live.length > 0 && response.data[0] != undefined){
                console.log(response.data[0]);
                setLive(response.data[0]);
                setIsLive(true);
                setLatest(false);
            } else {
                setLive('');
                setIsLive(false);
                setMostRecent(mostRecent);
                setLatest(true);
            }
            
        } 

        fetchData();
    }, [])

    const displayShow = (show) => {
        return (
            <div id="livenow">
                <div className="inner_wrapper">
                    Live Right Now: {liveshow}
                </div>
            </div>
        )
    }

    return (
        <>
            { isLive ? displayShow(liveshow.live) : "" }
            { latestVideo ? 
                <div id="livenow">
                    <div className="inner_wrapper">
                        <YoutubeLink href={mostRecentVideo}> Check up on what you missed here.</YoutubeLink>
                    </div>
                </div>
            : "" }
        </>
    );
};


const YoutubeLink = styled.a`
    text-decoration: none;
    color: white;
    text-transform: uppercase;
`

export default LiveNow;