import React from 'react';
import styled from 'styled-components';
import './Topbar.scss';

function Topbar(){
    return(
        <div id="topbar">
            <div className="inner_wrapper">
                <div className="leftside">
                    <LinkTo href="http://backstage.wdwnt.com">
                        My WDWNT &nbsp;
                    </LinkTo>  
                </div>
                <div className="rightside">
                    &nbsp;
                </div>
            </div>
        </div>
    )
}

const LinkTo = styled.a`
    color: white;
    text-decoration: none;
`

export default Topbar;